<template>
  <div>
    <v-container>
      <h1 class="pb-6">Government Service Desk</h1>
      <v-row>
        <v-col v-for="(menu, idx) in menus" :key="idx" cols="6" md="3" sm="3">
          <v-card ripple link :to="'/' + menu.url" rounded="lg" class="pa-6">
            <v-img class="ma-6" :src="getImage(menu.img)"></v-img>
            <p class="text-center">{{ menu.title }}</p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  data: function() {
    return {
      menus: [
        {
          img: "permit_applications.svg",
          title: "Permit Applications",
        },
        {
          img: "government_aid.svg",
          title: "Government Aid",
        },
        {
          img: "schedule_inspection.svg",
          title: "Schedule Inspection",
        },
        {
          img: "inspection_reports.svg",
          title: "Inspection Reports",
        },
        {
          img: "deforestation_filing.svg",
          title: "Deforestation Filing",
        },
        {
          img: "chat_with_ministry_of_ag.svg",
          title: "Chat with Ministry of Ag",
        },
        {
          img: "chat_with_ministry_of_livestock.svg",
          title: "Chat with Ministry of Livestock",
        },
        {
          img: "report_accident_or_crime.svg",
          title: "Report Accident or Crime",
        },
        {
          img: "history.svg",
          title: "History",
        },
        {
          img: "pay_fees.svg",
          title: "Pay Fees",
        },
      ],
    };
  },
  methods: {
    getImage(img) {
      return "/img/government_service_desk/" + img;
    },
  },
};
</script>
